<template>
  <div class="main-conent main-conent-minheight">
    <el-card class="SearchBox" v-loading="loading">
      <el-row>
        <el-col :span="8">
          <el-button type="primary" @click="() => ToAddPage()">新增</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card v-loading="loading">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="标题" width="180" />
        <el-table-column prop="sort" label="备注" width="100" />
        <el-table-column prop="address" label="二维码">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="FullImgUrl(scope.row.imgUrl)" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="shopDialogState" :visible.sync="showDialog" width="30%" :before-close="handleClose">
      <el-form label-position="right" :model="sceneData" label-width="80px" v-loading.lock="loading" ref="editForm"
        :rules="formRule">
        <el-form-item label="标题" prop="title">
          <el-input v-model="sceneData.title"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="sort">
          <el-input v-model="sceneData.remark"></el-input>
        </el-form-item>
        <el-row shadow="never" class="">
          <el-button type="primary" @click="Submit('editForm')" :loading="loading">
            提交
          </el-button>
          <el-button @click="CloseDialog">
            关闭
          </el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { addActivityScene, queryActivityScene } from '@/service/activity.js';
import { shopFormRule } from '@/utlis/rules/activityRule.js';
export default {
  data() {
    return {
      showDialog: false,
      loading: false,
      activeId: 0,
      tableData: [],
      formRule: shopFormRule,
      sceneData: {
        id: 0,
        activityId: 0,
        title: '',
        remark: '',
        imgUrl: '',
      }
    }
  },
  created() {
    this.activeId = parseInt(this.$route.query.activeId);
    this.DataBinding();
  },
  computed: {
    FullImgUrl() {
      return function (img) {
        let fullImg = process.env.VUE_APP_HOST + img;
        return fullImg;
      }
    },
    shopDialogState() {
      if (this.sceneData.id > 0)
        return "编辑";
      else
        return "新增";
    }
  },
  methods: {
    //数据加载
    DataBinding() {
      this.loading = true;
      let request = {
        activityId: this.activeId,
      };
      queryActivityScene(request).then(res => {
        this.tableData = res.data;
        this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //新增
    ToAddPage() {
      this.showDialog = true;
    },
    //搜索
    searchList() {

    },
    //关闭弹窗
    handleClose() {
      this.sceneData.id = 0;
      this.sceneData.title = '';
      this.sceneData.remark = '';
    },
    //关闭弹窗
    CloseDialog() {
      this.showDialog = false;
      this.DataBinding();
    },
    //提交表单
    Submit(formName) {
      this.loading = true;
      this.sceneData.activityId = this.activeId;
      //表单数据验证
      this.$refs[formName].validate(valid => {
        if (valid) {
          addActivityScene(this.sceneData).then(res => {
            if (res.code == 0) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.DataBinding();
            } else {
              this.$message.error(res.message);
            }
            this.loading = false;
          });
        }
      });
    },
    //删除门店
    handleDelete(index, data) {
      if (data.id > 0) {
        this.$confirm('此操作将永久删门店【' + data.title + '】, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteShop(data.id).then(res => {
            this.DataBinding();
          })
        });
      }
    }
  }
}
</script>
<style scoped Lang="scss">
.SearchBox {
  margin-bottom: 10px;
}
</style>